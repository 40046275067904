<template>
  <v-data-table
    :headers="headers"
    :items="data"
    class="elevation-1"
    hide-default-footer
    :items-per-page="-1"
  >
    <template v-slot:item="{ item }">
      <tr>
        <td>
          <v-checkbox
            v-model="item.checked"
            color="#008479"
            dense
            class="checkbox-role"
            @change="editCheckbox($event, item)"
          ></v-checkbox>
        </td>
        <td>{{ item.name }}</td>
      </tr>
    </template>
  </v-data-table>
</template>
<script>
export default {
  name: 'TableQuestion',
  props: {
    data: {
      type: Array,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
  },
  methods: {
    editCheckbox(value, question) {
      return value
        ? this.$emit('select', question)
        : this.$emit('remove', question);
    },
  },
};
</script>
<style lang="scss">
.checkbox-role {
  .v-input__control .v-messages {
    display: none;
  }
}
</style>
